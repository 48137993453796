export const data = {
  panelImageName: "jornalistasC.png",
  divisorText: "Contabilidade para Jornalistas",
  content: {
    imageName: "ProfissionaisSaude.jpg",
    title: "Como ser nosso cliente?",
    text:
      "Você que atua como repórter, redator, redatora, escritor, escritora e quer proteger seus rendimentos, fique atento as tributações e riscos. Entre em contato, vamos orienta-los.",
    options: [
      "Academias",
      "Clínicas de Estética",
      "Dentistas",
      "Fisioterapeutas",
      "Médicos",
    ],
    contactText:
      "Tenha uma contabilidade personalizada, cadastre-se e receba nosso contato",
  },
}
